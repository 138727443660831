import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertContainer, AlertList } from "react-bs-notifier";
import { injectIntl } from 'react-intl';

/**
 * @SEE https://chadly.github.io/react-bs-notifier/
 *
 */
class Alerts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      position: this.props.position || 'bottom-left', //top-right (default), top-left, bottom-right, bottom-left
      alert: [],
      alerts: [{
        id: 0,
        type: "info",
        message: this.props.intl.formatMessage({ id: "alert.generic.info" }),
        show: true,
      }, {
        id: 1,
        type: "success",
        message: this.props.intl.formatMessage({ id: "alert.generic.success" }),
        show: true
      }, {
        id: 2,
        type: "danger",
        message: this.props.intl.formatMessage({ id: "alert.generic.error" }),
        show: true
      }, {
        id: 3,
        type: "warning",
        message: this.props.intl.formatMessage({ id: "alert.generic.error" }),
        show: true
      }]
    }

    this.getAlert = this.getAlert.bind(this);
  }

  getAlert(nextProps) {
    for (var i = 0; i < this.state.alerts.length; i++) {
      if (this.state.alerts[i].type === nextProps.genericAlert.type) {
        let alert = this.state.alerts[i];
        if (nextProps.genericAlert.msg) {
          alert.message = nextProps.genericAlert.msg
        }
        this.setState({ alert: [alert] });
        break;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.genericAlert && nextProps.genericAlert.show)
      this.getAlert(nextProps);
  }

  render() {

    let timeout = 6000
    if (this.props.timeout)
      timeout = this.props.timeout * 1000

    if (this.props.timeout === 0)
      timeout = 0

    if (this.props.genericAlert && this.props.genericAlert.show) {
      return (
        <AlertList alerts={this.state.alert} timeout={timeout} onDismiss={this.props.onDismiss} position={this.state.position} />
      )
    }

    if (this.props.isShowingSuccessAlert) {
      return (
        <AlertContainer position={this.state.position} >
          <Alert id={(new Date()).getTime()} type="success" headline={this.props.headline} timeout={timeout} onDismiss={this.props.onDismiss}>
            {this.props.message}
          </Alert>
        </AlertContainer>
      )
    }

    if (this.props.isShowingErrorAlert) {
      return (
        <AlertContainer position={this.state.position}>
          <Alert id={(new Date()).getTime()} type="danger" headline={this.props.headline} timeout={timeout} onDismiss={this.props.onDismiss}>
            {this.props.message}
          </Alert>
        </AlertContainer>
      )
    }

    return null;
  }
}


const propTypes = {
  intl: PropTypes.object.isRequired
};

Alerts.propTypes = propTypes;

export default injectIntl(Alerts, {
  withRef: true,
});
