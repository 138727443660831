const ReportService = {
  getDefaultPager() {
    return {
      numberOfElements: undefined,
      size: 50,
      sort: undefined,
      totalElements: undefined,
      totalPages: undefined,
      pageNumber: 1
    };
  }
};

export default ReportService;
