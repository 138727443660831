import ClientHelper from "./ClientHelper";
import StorageHelper from "./StorageHelper";

const UserHelper = {
    checkPermission(perm) {
        let user = this.getUser();

        if (user && user.profiles) {
            if (user.profiles.indexOf("superUser") === 0) return true;
        }

        let freeAccess = ["tracker", "config", "logout", "mosaic"];

        if (ClientHelper.getClient() && ClientHelper.getClient().setup.type === "free") {
            freeAccess = ["isca", "command", "logout"];
        }

        if (freeAccess.indexOf(perm) > -1) return true;

        if (user && user.permissions) {
            for (var i = 0; i < user.permissions.length; i++) {
                if (user.permissions[i].name === perm) {
                    return true;
                }
            }
        }
        return false;
    },

    checkProfile(profile) {
        let user = this.getUser();

        if (user && user.profiles) {
            if (user.profiles.indexOf(profile) === 0) return true;
        }
        return false;
    },

    getUserColor() {
        let bgColor = ClientHelper.getClient().primaryColor;
        if (this.getUser() && this.getUser().variables && this.getUser().variables.color)
            bgColor = this.getUser().variables.color;

        return {
            backgroundColor: bgColor,
            borderColor: bgColor,
            backgroundLogo:
                ClientHelper.getClient().backgroundColor !== "NO"
                    ? ClientHelper.getClient().backgroundColor
                    : ClientHelper.getClient().primaryColor
        };
    },

    getUser() {
        if (StorageHelper.get("user")) return JSON.parse(StorageHelper.get("user"));

        return { variables: {} };
    },

    getUserId() {
        return UserHelper.getUser()?.id;
    },

    setUser(user) {
        StorageHelper.set("user", JSON.stringify(user));
    },

    getDevice() {
        if (StorageHelper.get("device")) return JSON.parse(StorageHelper.get("device"));

        return {};
    },

    setDevice(device) {
        StorageHelper.set("device", JSON.stringify(device));
    },

    getLastSort() {
        if (StorageHelper.get("lastSort")) return JSON.parse(StorageHelper.get("lastSort"));

        return {};
    },

    setLastSort(lastSort) {
        StorageHelper.set("lastSort", JSON.stringify(lastSort));
    },

    getToken() {
        return StorageHelper.get("token");
    },

    setToken(token) {
        StorageHelper.set("token", token);
    },

    logout(cb) {
        StorageHelper.remove("token");
        StorageHelper.remove("user");
        StorageHelper.remove("lastSort");
        if (cb) cb();
        this.onChange(false);
    },

    loggedIn() {
        return !!StorageHelper.get("token");
    },
    onChange() { }
};

export default UserHelper;
