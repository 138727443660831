import React, { Component } from "react";

import "./Loader.css";

class LoaderPage extends Component {
    render() {
        var style = {
            position: "fixed",
            left: "50%",
            right: 0,
            transform: "translateX(-50%)",
            zIndex: 999
        };

        return (
            <div className="Loader container Loader-page fadeIn">
                <div className="row">
                    <div className="col-xs-1  col-xs-offset-6">
                        <div className="load-wrapp" style={style}>
                            <div className="load-3">
                                <div className="line" />
                                <div className="line" />
                                <div className="line" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoaderPage;
