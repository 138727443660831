import React, { Component } from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import FontAwesome from "react-fontawesome";
import UserHelper from "./../../Utils/Helpers/UserHelper";

class FreeNav extends Component {
    handleClick(selectedKey) {
        switch (selectedKey) {
            default:
        }
    }

    render() {
        return (
            <Navbar collapseOnSelect fluid staticTop>
                <Navbar.Header>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav onSelect={this.handleClick}>
                        <NavDropdown
                            eventKey={3}
                            title={
                                <span>
                                    <FontAwesome name="bars" /> <FormattedMessage id="menu" />
                                </span>
                            }
                            id="basic-nav-dropdown"
                        >
                            <LinkContainer to={"/" + this.props.path + "/command"}>
                                <NavItem eventKey={2}>
                                    <FontAwesome name="newspaper" /> <FormattedMessage id="menu.command" />{" "}
                                </NavItem>
                            </LinkContainer>
                        </NavDropdown>

                        <LinkContainer to={"/" + this.props.path + "/isca"}>
                            <NavItem eventKey={1}>
                                <FontAwesome name="map" /> <FormattedMessage id="menu.isca" />{" "}
                            </NavItem>
                        </LinkContainer>
                    </Nav>
                    <Nav pullRight onSelect={this.handleClick}>
                        <NavItem eventKey="user">
                            {" "}
                            <FontAwesome name="user-circle" /> {UserHelper.getUser().user}
                        </NavItem>
                        <LinkContainer to={"/" + this.props.path + "/logout"}>
                            <NavItem eventKey={3.1}>
                                <FontAwesome name="sign-out-alt" className="icon-red" />{" "}
                                <span className="visible-xs-inline">
                                    <FormattedMessage id="menu.logout" />
                                </span>
                            </NavItem>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const propTypes = {
    intl: PropTypes.object.isRequired
};

FreeNav.propTypes = propTypes;

export default injectIntl(FreeNav, {
    withRef: true
});
