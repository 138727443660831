import { Button, Checkbox, FormControl, FormGroup, Modal, Col, Row, ControlLabel } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import FontAwesome from "react-fontawesome";
import React, { Component } from "react";
import EventService from "../../Services/Event/EventService";
import UserHelper from "../../Utils/Helpers/UserHelper";
import moment from "moment";

class NoticeModal extends Component {
    _isMounted = true;

    constructor(props) {
        super(props);

        this.state = {
            clientKey: "",
            message: "",
            notice: {message: "Carregando..."},
            visibleNoticeModel: false,
            dontShowModalAgain: false,
        };

        this.hideNoticeModel = this.hideNoticeModel.bind(this);
        this.handleChangeItem = this.handleChangeItem.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(newProps) {
        if (newProps.visibleNoticeModel !== undefined) {
            this.setState({ visibleNoticeModel: newProps.visibleNoticeModel });
        }

        if (newProps.notice !== undefined) {
            let notice = newProps.notice;

            this.setState({
                notice: newProps.notice,
                dontShowModalAgain: notice.notice_view.no_notice
            });
        }
    }

    hideNoticeModel() {
        this.props.hideNoticeModel();

        let noticeView = {
            user_id: UserHelper.getUserId(),
            notices_id: this.state.notice.id,
            no_notice: this.state.dontShowModalAgain
        };
        EventService.updateLastSeenNotice(this, noticeView);
    }

    handleChangeItem() {
        this.setState({
            dontShowModalAgain: !this.state.dontShowModalAgain
        });
    }

    render() {
        return (
            <Modal show={this.state.visibleNoticeModel} onHide={this.hideNoticeModel}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div>
                            <FormattedMessage id="notices" />{" "}
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.notice.message
                                }}
                            />
                        </Col>
                    </Row>
                    <br/><br/>
                    <Row>
                        <Col md={12}>
                            <span style={{fontWeight: "bold"}}>Publicado em:</span> {moment(this.state.notice.updated_at).format('DD/MM/YYYY H:mm:ss')}
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6} className="text-left">
                            <Checkbox
                                checked={this.state.dontShowModalAgain}
                                value={this.state.dontShowModalAgain}
                                id="dontShowModalAgain"
                                onChange={this.handleChangeItem}
                            >
                                <b>
                                    <FormattedMessage id="system.dontShowModalAgain" />
                                </b>
                            </Checkbox>
                        </Col>
                        <Col md={6}>
                            <Button className="btn-danger" onClick={this.hideNoticeModel}>
                                <FontAwesome name="times" /> <FormattedMessage id="close" />
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(NoticeModal);
