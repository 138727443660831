import React from "react";
import Moment from "moment";
import "moment-timezone";
import PropTypes from "prop-types";
import { FormattedDate, FormattedTime } from "react-intl";

class DateText extends React.PureComponent {
  render() {
    let Component = null;

    switch (this.props.type) {
      case "time":
        Component = (
          <FormattedTime
            value={Moment(this.props.date).toISOString()}
            hour="numeric"
            minute="numeric"
            second="numeric"
          />
        );
        break;
      case "date":
        Component = (
          <FormattedDate value={Moment(this.props.date).toISOString()} year="numeric" month="2-digit" day="2-digit" />
        );
        break;

      case "datetime":
        Component = (
          <>
            <FormattedDate value={Moment(this.props.date).toISOString()} year="numeric" month="2-digit" day="2-digit" />{" "}
            <FormattedTime
              value={Moment(this.props.date).toISOString()}
              hour="numeric"
              minute="numeric"
              second="numeric"
            />
          </>
        );
        break;

      default:
        Component = (
          <FormattedDate value={Moment(this.props.date).toISOString()} year="numeric" month="2-digit" day="2-digit" />
        );
        break;
    }
    return this.props.date ? Component : null;
  }
}

DateText.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(["datetime", "date", "time"])
};

export default DateText;
