import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import pt from "react-intl/locale-data/pt";
import Routes from "./routes";
import ComponentFactory from "./componentFactory";
import { BrowserRouter } from "react-router-dom";
import NotFound from "./Pages/NotFound";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";

// Services
import LocaleHelper from "./Utils/Helpers/LocaleHelper";

import ChangeHeader from "./Elements/Header/ChangeHeader";

// CSS
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/css/App.css";
import "./Assets/css/style.css";
import "./Assets/css/Header.css";
import "./Assets/css/notice.css";
import "./Assets/css/progress.css";
import "./Assets/css/Map.css";
import "./Assets/css/spacing.css";
import "./Elements/Grid/Grid.css";
import "./Assets/css/colors.css";
import "./Assets/css/responsive.css";
import "react-bootstrap-multiselect/css/bootstrap-multiselect.css";
import "fixed-data-table-2/dist/fixed-data-table.min.css";

import enLocale from "./Locales/en_default.json";
import ptLocale from "./Locales/pt.json";
import esLocale from "./Locales/es.json";

addLocaleData([...en, ...pt, ...es]);

const baseUrl = process.env.PUBLIC_URL || "/webcliente/";

class App extends Component {
    render() {
        var locale = LocaleHelper.getLocale() || "pt";
        var messages = enLocale.pt;

        Moment.locale(locale);
        momentLocalizer();

        // Hack for old ie and safari
        if (!window.intl && window.require) {
            window.require.ensure(
                [
                    "intl",
                    "intl/locale-data/jsonp/en.js",
                    "intl/locale-data/jsonp/es.js",
                    "intl/locale-data/jsonp/pt.js",
                    "intl/locale-data/jsonp/fr.js",
                    "intl/locale-data/jsonp/it.js"
                ],
                require => {
                    require("intl");
                    require("intl/locale-data/jsonp/en.js");
                    require("intl/locale-data/jsonp/es.js");
                    require("intl/locale-data/jsonp/pt.js");
                    require("intl/locale-data/jsonp/fr.js");
                    require("intl/locale-data/jsonp/it.js");
                }
            );
        }

        switch (locale) {
            case "pt-BR":
            case "pt":
                messages = ptLocale.pt;
                break;
            case "en":
                messages = enLocale.en;
                break;
            case "es":
                messages = esLocale.es;
                break;
            default:
                messages = ptLocale.pt;
        }

        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={baseUrl}>
                    <div>
                        <ChangeHeader {...this.props} />

                        <div className="wrapper">
                            <Switch>
                                {Routes.get().map((i, k) => (
                                    <Route
                                        exact
                                        path={i.path}
                                        key={k}
                                        render={r => <ComponentFactory {...i} {...r} />}
                                    />
                                ))}
                                <Route component={NotFound} />
                            </Switch>
                        </div>
                    </div>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

export default App;
