import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";

import { Row, Col } from "react-bootstrap";

ReactDOM.render(
    <div>
        <Row>
            <Col xs={12}>
                <App />
            </Col>
        </Row>
    </div>,
    document.getElementById("root")
);
