import Config from "./../../Config";
import ClientHelper from "./../../Utils/Helpers/ClientHelper";
import LocaleHelper from "./../../Utils/Helpers/LocaleHelper";
import UserHelper from "./../../Utils/Helpers/UserHelper";

const Api = {
    _checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            if (response.status >= 400 && response.status <= 404) {
                return response.json().then((resp) => {
                    UserHelper.logout();
                    window.location.reload(true);
                });
            }
            return response;
        }
    },

    _parseJSON(response) {
        if (typeof response === "object") return response.json();
        else return response;
    },

    _call(url, method, data, callBack, service, instance) {
        data["locale"] = LocaleHelper.getLocale();
        if (url !== "api/client/loadClient") {
            data["key"] = ClientHelper.getClient().path;
            data["variables"] = UserHelper.getUser() ? UserHelper.getUser().variables : [];
        }

        let serverUrl = service ? Config.api[Config.env][service] : Config.api[Config.env]["url"];

        let header = {
            method: method.toUpperCase(),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + UserHelper.getToken()
            }
        };
        if (method.toUpperCase() !== "GET") header.body = method.toUpperCase() !== "GET" ? JSON.stringify(data) : {};
        else header.params = method.toUpperCase() === "GET" ? JSON.stringify(data) : {};

        fetch(serverUrl + url, header)
            .then(this._checkStatus)
            .then(this._parseJSON)
            .then((response, err) => {
                if (instance) {
                    if (!instance._isMounted) return;
                    if (err === "failed") {
                        instance.setState({
                            genericAlert: { show: true, type: "danger" }
                        });
                    } else {
                        if (response.error !== 0) {
                            instance.setState({
                                genericAlert: {
                                    show: true,
                                    type: "danger",
                                    msg: response.msg
                                }
                            });
                        } else {
                            if (callBack) callBack(response, err);
                        }
                    }
                } else {
                    if (callBack) callBack(response, err);
                }
            })
            .catch(function (error) {
                console.log("erro", error);
                if (callBack) callBack({ error: 1 }, "failed");
            });
    },

    get(url, data, callBack, service, instance) {
        this._call(url, "GET", data, callBack, service, instance);
    },

    post(url, data, callBack, service, instance) {
        this._call(url, "POST", data, callBack, service, instance);
    }
};

export default Api;
