import Config from "./../../Config";

class StorageHelper {
    /**
     * @param {[type]} name     [description]
     * @param {[type]} data     [description]
     * @param {[type]} stringfy [description]
     */
    static set(name, data, stringfy) {
        if (stringfy) data = data ? JSON.stringify(data) : null;
        localStorage.setItem(Config.basename + "." + name, data);
    }

    /**
     * @param  {[type]} name   [description]
     * @param  {[type]} toJson [description]
     * @return string
     */
    static get(name, toJson) {
        let data = localStorage.getItem(Config.basename + "." + name);
        if (toJson && data) data = data ? JSON.parse(data) : null;
        return data;
    }

    /**
     * @param  {[type]} name [description]
     * @return {[type]}      [description]
     */
    static remove(name) {
        localStorage.removeItem(Config.basename + "." + name);
    }
}
export default StorageHelper;
