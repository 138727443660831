import _ from "lodash";

class Routes {
    static clientPath = "/:clientName";

    static buildPath(path) {
        let s = path.substr(1).split("/");
        s[0] = this.clientPath;

        return s.join("/");
    }

    static getByPath(path) {
        return _.find(this.get(), (i) => i.path === this.buildPath(path));
    }
    static get() {
        return [
            {
                path: this.clientPath + "/",
                dynamicImport: "Pages/Login/Login",
                hasHeader: false,
                headerType: "site",
                icon: "home",
                access: "public",
                translateId: "menu.home"
            },
            {
                path: this.clientPath + "/login",
                dynamicImport: "Pages/Login/Login",
                hasHeader: false,
                headerType: "",
                access: "public",
                icon: "",
                translateId: ""
            },
            {
                path: this.clientPath + "/temperature",
                hasHeader: true,
                headerType: "admin",
                icon: "home",
                translateId: "menu.tracker",
                access: "private",
                dynamicImport: "Pages/Temperature/OSMTemperature"
            },
            {
                path: this.clientPath + "/isca",
                hasHeader: true,
                headerType: "admin",
                icon: "home",
                translateId: "menu.tracker",
                access: "private",
                dynamicImport: "Pages/Lbs/Lbs"
            },
            {
                path: this.clientPath + "/tracker",
                hasHeader: true,
                headerType: "admin",
                icon: "home",
                translateId: "menu.tracker",
                access: "private",
                dynamicImport: "Pages/Tracker/OSMTracker"
            },
            {
                path: this.clientPath + "/mosaic",
                hasHeader: true,
                headerType: "admin",
                icon: "home",
                translateId: "menu.mosaic",
                access: "private",
                dynamicImport: "Pages/Mosaic/Mosaic"
            },
            {
                path: this.clientPath + "/collaborativeNetwork",
                hasHeader: true,
                headerType: "admin",
                icon: "home",
                translateId: "menu.tracker",
                access: "private",
                dynamicImport: "Pages/CollaborativeNetwork/OSMCollaborativeNetwork"
            },
            {
                path: this.clientPath + "/command",
                hasHeader: true,
                headerType: "admin",
                icon: "users",
                translateId: "menu.groups",
                access: "private",
                dynamicImport: "Pages/Command/CommandIndex"
            },
            {
                path: this.clientPath + "/logistics/address",
                hasHeader: false,
                headerType: "admin",
                icon: "location-arrow",
                translateId: "menu.address",
                access: "private",
                dynamicImport: "Pages/Logistics/Address/Address"
            },
            {
                path: this.clientPath + "/logistics/route",
                hasHeader: false,
                headerType: "admin",
                icon: "route",
                translateId: "menu.route",
                access: "private",
                dynamicImport: "Pages/Logistics/Route/Route"
            },
            {
                path: this.clientPath + "/logistics/routing",
                hasHeader: false,
                headerType: "admin",
                icon: "route",
                translateId: "menu.route",
                access: "private",
                dynamicImport: "Pages/Logistics/Routing/Index"
            },
            {
                path: this.clientPath + "/logistics/checkpoint",
                hasHeader: false,
                headerType: "admin",
                icon: "map-marker-alt",
                translateId: "menu.checkpoint",
                access: "private",
                dynamicImport: "Pages/Logistics/CheckPoint/CheckPointIndex"
            },
            {
                path: this.clientPath + "/fence",
                hasHeader: true,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/Fences/Fences"
            },
            {
                path: this.clientPath + "/subaccount",
                hasHeader: true,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/SubAccount/SubAccount"
            },
            {
                path: this.clientPath + "/associate/add",
                hasHeader: false,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/Associate/Associate"
            },
            {
                path: this.clientPath + "/logout",
                hasHeader: false,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/Logout"
            },
            {
                path: this.clientPath + "/associate/reports/reports",
                hasHeader: false,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/Associate/Reports/Reports"
            },
            {
                path: this.clientPath + "/groups",
                hasHeader: true,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/Group/Group"
            },
            {
                path: this.clientPath + "/reports",
                hasHeader: true,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/Reports/Reports"
            },
            {
                path: this.clientPath + "/config",
                hasHeader: false,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/Config/Config"
            },
            {
                path: this.clientPath + "/events",
                hasHeader: true,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.fences",
                access: "private",
                dynamicImport: "Pages/Events/Events"
            },
            {
                path: this.clientPath + "/dashboard",
                hasHeader: true,
                headerType: "admin",
                icon: "draw-polygon",
                translateId: "menu.dashboard",
                access: "public",
                dynamicImport: "Pages/Dashboard"
            },
            {
                path: this.clientPath + "/convoy",
                hasHeader: true,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Convoy/Convoy"
            },
            {
                path: this.clientPath + "/workingHours/drivers",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/WorkingHours/Drivers"
            },
            {
                path: this.clientPath + "/workingHours/reports",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/WorkingHours/Reports/Index"
            },
            {
                path: this.clientPath + "/maintenance/reports/reports",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Maintenance/Reports/Reports"
            },
            {
                path: this.clientPath + "/maintenance/input/preventive",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Maintenance/Input/Preventive/Preventive"
            },
            {
                path: this.clientPath + "/maintenance/input/releaseControl",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Maintenance/Input/ReleaseControl"
            },
            {
                path: this.clientPath + "/maintenance/input/releaseFuel",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Maintenance/Input/ReleaseFuel/ReleaseFuel"
            },
            {
                path: this.clientPath + "/maintenance/input/corrective",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Maintenance/Input/Corrective"
            },
            {
                path: this.clientPath + "/maintenance/input/downloadpreventive",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Maintenance/Input/DownloadPreventive"
            },
            {
                path: this.clientPath + "/maintenance/add",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Maintenance/Add/Maintenance"
            },
            {
                path: this.clientPath + "/workingHours/inputs",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/WorkingHours/Inputs"
            },
            {
                path: this.clientPath + "/workingHours/performance",
                hasHeader: false,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/WorkingHours/Performance"
            },
            {
                path: this.clientPath + "/commandCollaborativeNetwork",
                hasHeader: true,
                headerType: "admin",
                icon: "sitemap",
                translateId: "menu.convoy",
                access: "private",
                dynamicImport: "Pages/Command/commandCollaborativeNetwork"
            },
            {
                path: this.clientPath + "/visit",
                hasHeader: true,
                headerType: "admin",
                icon: "home",
                translateId: "menu.visit",
                access: "private",
                dynamicImport: "Pages/Visit/List"
            }
        ];
    }
}
export default Routes;
