import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Navbar,
    Nav,
    NavItem,
    NavDropdown,
    Tooltip,
    OverlayTrigger,
    Modal,
    FormGroup,
    InputGroup,
    FormControl,
    Glyphicon,
    Button,
    Alert,
    Row,
    Col,
    Popover
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import FontAwesome from "react-fontawesome";
import UserHelper from "./../../Utils/Helpers/UserHelper";
import UserService from "./../../Services/User/UserService";
import EventService from "./../../Services/Event/EventService";
import PagerHelper from "./../../Utils/Helpers/PagerHelper";
import AlertSound from "./../../Assets/Sounds/alert.mp3";
import ClientHelper from "./../../Utils/Helpers/ClientHelper";
import Alerts from "./../../Elements/Alerts/Alerts";
import DateText from "../DatePicker/DateText";
import NoticeModal from "./NoticeModal";
import NoticeEntity from "../../model/NoticeEntity";

class FullNav extends Component {
    _isMounted = true;

    _onDismiss = () => {
        this.setState({ genericAlert: { show: false } });
    };
    _onDismissNotificationAlert = () => {
        this.setState({ notificationAlert: { show: false } });
    };

    constructor(props) {
        super(props);

        let client = ClientHelper.getClient();
        if (!client.setup.map) client.setup.map = "osm";
        const perPage = 15;
        this.infiniteScrollRef = React.createRef();

        this.state = {
            events: [],
            emergencies: [],
            notice: new NoticeEntity({}),
            visibleNoticeModel: false,
            eventsCount: 0,
            eventPopup: false,
            genericAlert: { show: false, type: null },
            notificationAlert: { show: false, type: null },
            emergencyCount: 0,
            soundAlert: "",
            lastEvent: 0,
            hasSound: true,
            continuousSound: false,
            intervalId: "",
            clientStyle: UserHelper.getUserColor(),
            modalTitle: "",
            pager: PagerHelper.getDefaultPager(),
            eventsList: [],
            client: client,
            hideEvents: false,
            currentURL: "",
            eventType: "",
            finishAllStatus: false,
            eventPage: 1,
            emergencyPage: 1,
            loadingEvent: false,
            dontShowModalAgain: false,
        };

        this.componentDidUpdate = () => {
            if (this.infiniteScrollRef.current) {
                const evtType = this.state.eventType
                const intersectionObserver = new IntersectionObserver((entries) => {
                    const ratio = entries[0].intersectionRatio
                    const page = evtType === 'emg' ? this.state.emergencyPage : this.state.eventPage;
                    const count = evtType === 'emg' ? this.state.emergencyCount : this.state.eventsCount;
                    const newPage = page + 1;
                    if (ratio > 0) {
                        if (this.state.loadingEvent) {
                            return;
                        }
                        if (page * perPage >= count) {
                            return
                        }
                        if (evtType === 'emg') {
                            this.loadEmergencies(newPage)
                        } else {
                            this.loadEvents(newPage)
                        }

                    }
                });
                intersectionObserver.observe(this.infiniteScrollRef.current)
            }
        };

        this.loadEmergencies = (newPage) => {
            this.setState({ loadingEvent: true, emergencyPage: newPage },
                () => {
                    EventService.getEvents(
                        this,
                        {
                            userId: UserHelper.getUser().id,
                            page: newPage
                        },
                        (data) => {
                            const newEmergencies = [...this.state.emergencies, ...data.data.emergency.data]
                            this.setState({ emergencies: newEmergencies, loadingEvent: false, eventsList: newEmergencies });
                        }
                    )
                });
        }

        this.loadEvents = (newPage) => {
            this.setState({ loadingEvent: true, eventPage: newPage });
            EventService.getEvents(
                this,
                {
                    userId: UserHelper.getUser().id,
                    page: newPage
                },
                (data) => {
                    const newEvents = [...this.state.events, ...data.data.events.data]
                    this.setState({ events: newEvents, loadingEvent: false, eventsList: newEvents });
                }
            )
        }

        this.componentDidMount = () => {
            this._isMounted = true;
            this.setState({
                soundAlert: new Audio(AlertSound),
                hasSound: UserHelper.getUser().sound === "0" ? false : true,
                continuousSound: UserHelper.getUser().variables?.continuousSound === "false" ? false : true,
                eventPopup:
                    UserHelper.getUser() &&
                        UserHelper.getUser().variables &&
                        UserHelper.getUser().variables.showEventPopups === "false"
                        ? false
                        : true
            });
            this.disableEvents();
        };

        this.componentWillUpdate = () => {
            if (this.state.currentURL !== window.location.href && this.state.notificationAlert.show !== true) {
                this.getNotificationAlert();
            }
        };

        this.disableEvents = () => {
            let self = this;
            this.setState({
                hideEvents:
                    UserHelper.getUser().variables && UserHelper.getUser().variables.hideEvents === "true"
                        ? true
                        : false
            });
            clearInterval(this.state.intervalId);
            if (!self.state.hideEvents) {
                self.getEvents();
                let intervalId = setInterval(function () {
                    if (self._isMounted) self.getEvents();
                }, 60000);
                this.setState({ intervalId: intervalId });
            }
        };

        this.componentWillUnmount = () => {
            clearInterval(this.state.intervalId);
            this._isMounted = false;
        };

        this.close = () => {
            this.setState({ showModal: false });
        };

        this.open = () => {
            this.setState({ showModal: true });
        };

        this.handleComment = ($event, index) => {
            let fieldVal = $event.target.value;
            let wrapper = this.state.eventsList;
            wrapper[index].comment = fieldVal;
            this.setState({ eventsList: wrapper });
        };

        this.dismissComment = ($event, index) => {
            let wrapper = this.state.eventsList;
            wrapper[index].msg = "";
            this.setState({ eventsList: wrapper });
        };

        this.addComment = (event, index) => {
            EventService.addComment(this, index, {
                userId: UserHelper.getUser().id,
                event: this.state.eventsList[index]
            });
        };

        this.showEvents = () => {
            let wrapper = this.state.events.map(function (item) {
                item.comment = "";
                item.msg = "";
                return item;
            });
            this.setState({
                eventsList: wrapper,
                modalTitle: this.props.intl.formatMessage({ id: "menu.events" }),
                eventType: "evt"
            }, () => {
                const page = Math.ceil(this.state.events.length / perPage);
                this.setState({ eventPage: page });
            },
                this.props.intl);
            if (this.state.events.length > 0) this.open();
        };

        this.showEmergencies = () => {
            let wrapper = this.state.emergencies.map(function (item) {
                item.comment = "";
                item.msg = "";
                return item;
            });
            this.setState({
                eventsList: wrapper,
                modalTitle: this.props.intl.formatMessage({ id: "emergencies" }),
                eventType: "emg"
            }, () => {
                const page = Math.ceil(this.state.emergencies.length / perPage);
                this.setState({ emergencyPage: page });
            },
                this.props.intl);
            if (this.state.emergencies.length > 0) this.open();
        };

        this.showNotices = () => {
            this.setState({ visibleNoticeModel: true });
        };

        this.hideNoticeModel = () => {
            this.setState({ visibleNoticeModel: false });
        }

        this.getEventColor = (event) => {
            switch (event.type) {
                case "EMG":
                    return "danger";
                case "EVT":
                case "ALT":
                    return "warning";
                case "STT":
                case "910":
                    return "info";
                default:
                    return "success";
            }
        };

        this.finishEvent = (item, index) => {
            if (item === "evt" || item === "emg") {
                this.setState({ finishAllStatus: true });
            }
            EventService.finishEvent(
                this,
                index,
                {
                    userId: UserHelper.getUser().id,
                    event: this.state.eventsList[index],
                    type: item === "evt" || item === "emg" ? item : null
                },
                () => this.getEvents()
            );
        };

        this.getEvents = () => {
            if (UserHelper.getUser().id) {
                this.setState(
                    {
                        continuousSound: UserHelper.getUser().variables?.continuousSound === "false" ? false : true
                    },
                    () => {
                        EventService.getEvents(
                            this,
                            {
                                userId: UserHelper.getUser().id,
                                page: this.state.pager.pageNumber > 1 ? this.state.pager.pageNumber : 1
                            },
                            null,
                            this.props.intl
                        );
                    }
                );
            }
        };

        this.toogleMute = () => {
            UserService.toogleMute(this, {
                userId: UserHelper.getUser().id,
                sound: this.state.hasSound ? "0" : "1"
            });
        };

        this.getNotificationAlert = () => {
            if (UserHelper.getUser().variables) {
                if (
                    UserHelper.getUser().variables.msgNotification !== undefined &&
                    UserHelper.getUser().variables.msgNotification !== false
                ) {
                    this.setState({
                        notificationAlert: {
                            show: true,
                            type: "danger",
                            msg: (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: UserHelper.getUser().variables.msgNotification
                                    }}
                                />
                            )
                        },
                        currentURL: window.location.href
                    });
                }
            }
        };

        this._onDismiss = this._onDismiss.bind(this);
        this._onDismissNotificationAlert = this._onDismissNotificationAlert.bind(this);
    }

    render() {
        let { eventsList } = this.state;

        const tooltip = {
            emergency: (
                <Tooltip id="emergency">
                    <FormattedMessage id="menu.emergency" />
                </Tooltip>
            ),
            events: (
                <Tooltip id="events">
                    <FormattedMessage id="menu.events" />
                </Tooltip>
            ),
            notices: (
                <Tooltip id="notices">
                    <FormattedMessage id="notices" />
                </Tooltip>
            ),
            changePassword: (
                <Tooltip id="changePassword">
                    <FormattedMessage id="menu.changePassword" />
                </Tooltip>
            ),
            sound: (
                <Tooltip id="sound">
                    <FormattedMessage id="menu.sound" />
                </Tooltip>
            ),
            settings: (
                <Tooltip id="settings">
                    <FormattedMessage id="menu.settings" />
                </Tooltip>
            ),
            logout: (
                <Tooltip id="logout">
                    <FormattedMessage id="menu.logout" />
                </Tooltip>
            ),
            menu: (
                <Tooltip id="menu">
                    <FormattedMessage id="menu" />
                </Tooltip>
            ),
            isca: (
                <Tooltip id="isca">
                    <FormattedMessage id="menu.isca" />
                </Tooltip>
            ),
            collaborativeNetwork: (
                <Tooltip id="collaborativeNetwork">
                    <FormattedMessage id="menu.collaborativeNetwork" />
                </Tooltip>
            ),
            tracker: (
                <Tooltip id="tracker">
                    <FormattedMessage id="menu.tracker" />
                </Tooltip>
            ),
            temperature: (
                <Tooltip id="temperature">
                    <FormattedMessage id="menu.temperature" />
                </Tooltip>
            )
        };

        const popoverClientInfo = (
            <Popover id="popover-positioned-bottom">
                <div dangerouslySetInnerHTML={{ __html: this.state.client.clientInfo }} />
            </Popover>
        );

        const Submenu = this.props.submenu;
        var currentLocation = window.location;
        var gridType = "";

        if (currentLocation.pathname.includes("tracker")) {
            gridType = "menu.tracker";
        } else if (currentLocation.pathname.includes("isca")) {
            gridType = "menu.isca";
        } else if (currentLocation.pathname.includes("collaborativeNetwork")) {
            gridType = "menu.collaborativeNetwork";
        } else {
            gridType = "menu.monitoring";
        }

        return (
            <div>
                <Navbar collapseOnSelect fluid staticTop>
                    <Navbar.Header>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav onSelect={this.handleClick}>
                            <NavDropdown
                                eventKey={3}
                                title={
                                    <span>
                                        <OverlayTrigger placement="bottom" overlay={tooltip.menu}>
                                            <span>
                                                <FontAwesome name="bars" /> <FormattedMessage id="menu" />
                                            </span>
                                        </OverlayTrigger>
                                    </span>
                                }
                                id="basic-nav-dropdown"
                            >
                                {UserHelper.checkPermission("dashboard") && (
                                    <LinkContainer to={"/" + this.props.path + "/dashboard"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="clipboard-list" />{" "}
                                            <FormattedMessage id="menu.dashboard" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {(UserHelper.checkPermission("command") ||
                                    UserHelper.checkPermission("commandKeyboard")) && (
                                        <LinkContainer to={"/" + this.props.path + "/command"}>
                                            <NavItem eventKey={2}>
                                                <FontAwesome name="newspaper" /> <FormattedMessage id="menu.command" />{" "}
                                            </NavItem>
                                        </LinkContainer>
                                    )}

                                {UserHelper.checkPermission("commandCollaborativeNetwork") && (
                                    <LinkContainer to={"/" + this.props.path + "/commandCollaborativeNetwork"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="share-alt" />{" "}
                                            <FormattedMessage id="menu.commandCollaborativeNetwork" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("subaccount") && UserHelper.getUser().clientId !== null && (
                                    <LinkContainer to={"/" + this.props.path + "/subaccount"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="user" /> <FormattedMessage id="menu.subaccount" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("groups") && (
                                    <LinkContainer to={"/" + this.props.path + "/groups"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="layer-group" /> <FormattedMessage id="menu.groups" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("convoy") && (
                                    <LinkContainer to={"/" + this.props.path + "/convoy"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="sitemap" /> <FormattedMessage id="menu.convoy" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("associate") && (
                                    <LinkContainer to={"/" + this.props.path + "/associate/add"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="pencil-alt" /> <FormattedMessage id="menu.associate" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("workingHours") && (
                                    <LinkContainer to={"/" + this.props.path + "/workingHours/drivers"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="id-card" />{" "}
                                            <FormattedMessage id="menu.workingHours" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("maintenance") && (
                                    <LinkContainer to={"/" + this.props.path + "/maintenance/add"}>
                                        <NavItem eventKey={2}>
                                            <Glyphicon glyph="wrench" /> <FormattedMessage id="menu.maintenance" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("fence") && (
                                    <LinkContainer to={"/" + this.props.path + "/fence"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="crop" /> <FormattedMessage id="menu.fence" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("reports") && (
                                    <LinkContainer to={"/" + this.props.path + "/reports"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="chart-bar" /> <FormattedMessage id="menu.reports" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("events") && (
                                    <LinkContainer to={"/" + this.props.path + "/events"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="eye" /> <FormattedMessage id="menu.events" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {this.state.client.setup.map === "google" && UserHelper.checkPermission("logistics") && (
                                    <LinkContainer to={"/" + this.props.path + "/logistics/address"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="list-alt" /> <FormattedMessage id="menu.logistics" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}
                                {this.state.client.setup.map === "osm" && UserHelper.checkPermission("logistics") && (
                                    <LinkContainer to={"/" + this.props.path + "/logistics/checkpoint"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="list-alt" /> <FormattedMessage id="menu.logistics" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("visit") && (
                                    <LinkContainer to={"/" + this.props.path + "/visit"}>
                                        <NavItem eventKey={2}>
                                            <Glyphicon glyph="wrench" /> <FormattedMessage id="menu.visit" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}
                            </NavDropdown>

                            <NavDropdown
                                eventKey={3}
                                title={
                                    <span>
                                        <OverlayTrigger placement="bottom" overlay={tooltip.menu}>
                                            <span>
                                                <FontAwesome name="map-marked-alt" /> <FormattedMessage id={gridType} />
                                            </span>
                                        </OverlayTrigger>
                                    </span>
                                }
                                id="basic-nav-dropdown"
                            >
                                <LinkContainer to={"/" + this.props.path + "/tracker"}>
                                    <NavItem eventKey={2}>
                                        <FontAwesome name="map-marker-alt" /> <FormattedMessage id="menu.tracker" />{" "}
                                    </NavItem>
                                </LinkContainer>

                                {UserHelper.checkPermission("isca") && (
                                    <LinkContainer to={"/" + this.props.path + "/isca"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="map" /> <FormattedMessage id="menu.isca" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                {UserHelper.checkPermission("collaborativeNetwork") && (
                                    <LinkContainer to={"/" + this.props.path + "/collaborativeNetwork"}>
                                        <NavItem eventKey={2}>
                                            <FontAwesome name="fas fa-globe" />{" "}
                                            <FormattedMessage id="menu.collaborativeNetwork" />{" "}
                                        </NavItem>
                                    </LinkContainer>
                                )}

                                <LinkContainer to={"/" + this.props.path + "/mosaic"}>
                                    <NavItem eventKey={2}>
                                        <FontAwesome name="fas fa-globe" /> <FormattedMessage id="menu.mosaic" />{" "}
                                    </NavItem>
                                </LinkContainer>
                            </NavDropdown>
                        </Nav>

                        <Nav pullRight onSelect={this.handleClick}>
                            {!this.state.hideEvents && this.state.notice?.id && (
                                <OverlayTrigger placement="bottom" overlay={tooltip.notices}>
                                    <NavItem eventKey={1.1} onClick={this.showNotices}>
                                        <FontAwesome name="fa fa-comment" className="icon-white" /> ({"1"})
                                        <span className="visible-xs-inline">
                                            <FormattedMessage id="notices" />
                                        </span>
                                    </NavItem>
                                </OverlayTrigger>
                            )}
                            {!this.state.hideEvents && (
                                <OverlayTrigger placement="bottom" overlay={tooltip.emergency}>
                                    <NavItem eventKey={1.1} onClick={this.showEmergencies}>
                                        <FontAwesome name="exclamation" className="icon-red" />{" "}
                                        <span className="badge badge-notify">{this.state.emergencyCount}</span>{" "}
                                        <span className="visible-xs-inline">
                                            <FormattedMessage id="menu.emergency" />
                                        </span>
                                    </NavItem>
                                </OverlayTrigger>
                            )}
                            {!this.state.hideEvents && (
                                <OverlayTrigger placement="bottom" overlay={tooltip.events}>
                                    <NavItem eventKey={1.1} onClick={this.showEvents}>
                                        <FontAwesome name="flag" /> ({this.state.eventsCount})
                                        <span className="visible-xs-inline">
                                            <FormattedMessage id="menu.events" />
                                        </span>
                                    </NavItem>
                                </OverlayTrigger>
                            )}

                            {this.state.client.clientInfo && (
                                <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    placement="bottom"
                                    overlay={popoverClientInfo}
                                >
                                    <NavItem>
                                        <FontAwesome name="headset" />{" "}
                                        <span className="visible-xs-inline">
                                            {" "}
                                            <FormattedMessage id="grid.information" />
                                        </span>
                                    </NavItem>
                                </OverlayTrigger>
                            )}

                            {UserHelper.checkPermission("users") && (
                                <OverlayTrigger placement="bottom" overlay={tooltip.changePassword}>
                                    <LinkContainer to={"/" + this.props.path + "/config"} active={false}>
                                        <NavItem eventKey="user">
                                            {" "}
                                            <FontAwesome name="user-circle" />{" "}
                                            <span className="visible-lg-inline visible-xs-inline">
                                                {" "}
                                                {UserHelper.getUser().user}{" "}
                                            </span>
                                        </NavItem>
                                    </LinkContainer>
                                </OverlayTrigger>
                            )}

                            {!UserHelper.checkPermission("users") && (
                                <NavItem>
                                    {" "}
                                    <FontAwesome name="user-circle" />{" "}
                                    <span className="visible-lg-inline visible-xs-inline">
                                        {" "}
                                        {UserHelper.getUser().user}{" "}
                                    </span>
                                </NavItem>
                            )}

                            <OverlayTrigger placement="bottom" overlay={tooltip.sound}>
                                <NavItem eventKey="update" onClick={this.toogleMute}>
                                    {this.state.hasSound ? (
                                        <FontAwesome name="volume-up" />
                                    ) : (
                                        <FontAwesome name="volume-off" />
                                    )}
                                    <span className="visible-xs-inline">
                                        {" "}
                                        <FormattedMessage id="menu.sound" />
                                    </span>
                                </NavItem>
                            </OverlayTrigger>

                            {UserHelper.checkPermission("setup") && (
                                <OverlayTrigger placement="left" overlay={tooltip.settings}>
                                    <LinkContainer to={"/" + this.props.path + "/config"}>
                                        <NavItem eventKey="config">
                                            <FontAwesome name="cog" />{" "}
                                            <span className="visible-xs-inline">
                                                <FormattedMessage id="menu.settings" />
                                            </span>
                                        </NavItem>
                                    </LinkContainer>
                                </OverlayTrigger>
                            )}

                            <OverlayTrigger placement="left" overlay={tooltip.logout}>
                                <LinkContainer to={"/" + this.props.path + "/logout"}>
                                    <NavItem eventKey={3.1}>
                                        <FontAwesome name="sign-out-alt" className="icon-red" />{" "}
                                        <span className="visible-xs-inline">
                                            <FormattedMessage id="menu.logout" />
                                        </span>
                                    </NavItem>
                                </LinkContainer>
                            </OverlayTrigger>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div>
                    {this.props.submenu && (
                        <div className="submenu">
                            <Submenu {...this.props} />
                        </div>
                    )}
                </div>

                <Modal show={this.state.showModal} onHide={this.close} bsSize="large">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div>
                                {this.state.modalTitle}{" "}
                                <Button
                                    className="btn-primary"
                                    style={this.state.clientStyle}
                                    onClick={() => this.finishEvent(this.state.eventType)}
                                    disabled={this.state.finishAllStatus}
                                >
                                    {this.state.finishAllStatus && <FontAwesome name="spinner" spin />}{" "}
                                    <FontAwesome name="check-double" /> <FormattedMessage id="finishAll" />
                                </Button>
                            </div>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            {eventsList.map((item, key) => (
                                <Col md={12} key={key}>
                                    <div className={"notice notice-" + this.getEventColor(item)}>
                                        <strong
                                            dangerouslySetInnerHTML={{
                                                __html: item.complement
                                                    ? item.complement.substring(0, 1) === ":"
                                                        ? item.description + " - " + item.complement.substring(1)
                                                        : item.description + " - " + item.complement
                                                    : item.activationType === "ALT" && item.activationId === "1"
                                                        ? item.description + " - " + item.speed + " km/h"
                                                        : item.description
                                            }}
                                        ></strong>{" "}
                                        <small>
                                            <strong>
                                                <FormattedMessage id="device" />
                                            </strong>
                                            :{" "}
                                            {item.isIsca === "true" &&
                                                item.model !== "ST380" &&
                                                item.model !== "ST380-N" &&
                                                currentLocation.pathname.includes("isca")
                                                ? item.deviceId
                                                : item.licensePlate + (item.label ? " - " + item.label : "")}
                                        </small>{" "}
                                        {(item.mDrive || item.driverName) && (
                                            <small>
                                                <strong>
                                                    <FormattedMessage id="grid.driver" />
                                                </strong>
                                                : {item.mDrive ? item.mDrive : item.driverName}
                                            </small>
                                        )}{" "}
                                        <small>
                                            <strong>
                                                <FormattedMessage id="date" />
                                            </strong>
                                            : <DateText date={item.date} type="datetime" />
                                        </small>
                                        <br />
                                        <small>
                                            <strong>
                                                <FormattedMessage id="grid.address" />
                                            </strong>
                                            : {item.address}
                                        </small>
                                        <Row className="comments-line">
                                            {item.comments.map((item, key) => (
                                                <div className="notice notice-sm" key={key}>
                                                    <strong>
                                                        <DateText date={item.date} type="datetime" />
                                                    </strong>
                                                    : {item.comment}
                                                </div>
                                            ))}
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <InputGroup.Addon>
                                                            <FontAwesome name="comment" />
                                                        </InputGroup.Addon>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            value={item.comment}
                                                            id={String(item.id)}
                                                            onChange={(e) => this.handleComment(e, key)}
                                                            placeholder={this.props.intl.formatMessage({
                                                                id: "comment"
                                                            })}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                                <Button
                                                    style={this.state.clientStyle}
                                                    onClick={() => this.addComment(item, key)}
                                                    disabled={!item.comment}
                                                    className="btn-primary"
                                                >
                                                    <FontAwesome name="pencil-alt" />{" "}
                                                    <FormattedMessage id="addComment" />
                                                </Button>
                                                {" - "}
                                                <Button
                                                    style={this.state.clientStyle}
                                                    onClick={() => this.finishEvent(item, key)}
                                                    className="btn-primary"
                                                >
                                                    <FontAwesome name="check" /> <FormattedMessage id="finish" />
                                                </Button>
                                                {item.msg && (
                                                    <Alert
                                                        className="paddingTop"
                                                        bsStyle="success"
                                                        onDismiss={() => this.dismissComment(item, key)}
                                                    >
                                                        {item.msg}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            ))}
                            <br></br>
                            <div>
                                <div className="col-md-12" ref={this.infiniteScrollRef}></div>
                                <div className="text-center">
                                    {this.state.loadingEvent && <FontAwesome name="spinner" spin />}
                                </div>
                            </div>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="btn-primary"
                            style={this.state.clientStyle}
                            onClick={() => this.finishEvent(this.state.eventType)}
                            disabled={this.state.finishAllStatus}
                        >
                            {this.state.finishAllStatus && <FontAwesome name="spinner" spin />}{" "}
                            <FontAwesome name="check-double" /> <FormattedMessage id="finishAll" />
                        </Button>
                        <Button className="btn-danger" onClick={this.close}>
                            <FontAwesome name="times" /> <FormattedMessage id="close" />
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Alerts
                    genericAlert={this.state.genericAlert}
                    position="top-right"
                    timeout={0}
                    onDismiss={this._onDismiss}
                />

                <Alerts
                    genericAlert={this.state.notificationAlert}
                    position="top-left"
                    timeout={0}
                    onDismiss={this._onDismissNotificationAlert}
                />

                <NoticeModal
                    visibleNoticeModel={this.state.visibleNoticeModel}
                    notice={this.state.notice}
                    hideNoticeModel={this.hideNoticeModel}
                />
            </div>
        );
    }
}

const propTypes = {
    intl: PropTypes.object.isRequired
};

FullNav.propTypes = propTypes;

export default injectIntl(FullNav, {
    withRef: true
});
