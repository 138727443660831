import React from "react";
import { withRouter } from "react-router";
import Routes from "./../../routes";
import Header from "./Header";

class ChangeHeader extends React.Component {
    render() {
        let route = Routes.getByPath(this.props.location.pathname);
        let header = null;
        if (route && route.hasHeader) {
            header = (
                <Header
                    path={this.props.location.pathname.split("/")[1]}
                    name={route.translateId}
                    icon={route.icon}
                    submenu={route.submenu && route.submenu}
                />
            );
        }

        return header;
    }
}

export default withRouter(ChangeHeader);
