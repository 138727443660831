import React, { Component } from "react";
import FreeNav from "./FreeNav";
import FullNav from "./FullNav";
import Config from "./../../Config";
import UserHelper from "./../../Utils/Helpers/UserHelper";
import ClientHelper from "./../../Utils/Helpers/ClientHelper";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logo: undefined
        };
    }

    componentDidMount() {
        var user = UserHelper.getUser();
        var client = ClientHelper.getClient();

        const cliLogoPath = Config.logo.path + user.cliLogo;
        const userLogoPath = Config.logo.userPath + "desktopweb/lib/img/" + user.logo;
        const clientLogoPath = Config.logo.path + client.logo;

        if (user.cliLogo) {
            this.checkFileExists(cliLogoPath)
                .then(exists => {
                    if (exists) {
                        this.setState({ logo: cliLogoPath });
                    } else {
                        this.checkUserLogo(userLogoPath, clientLogoPath);
                    }
                })
                .catch(error => {
                    console.error("Erro ao verificar cliLogo no S3:", error);
                    this.checkUserLogo(userLogoPath, clientLogoPath);
                });
        } else {
            this.checkUserLogo(userLogoPath, clientLogoPath);
        }
    }

    checkFileExists(url) {
        return fetch(url, { method: 'HEAD' })
            .then(response => response.ok)
            .catch(() => false);
    }

    checkUserLogo(userLogoPath, clientLogoPath) {
        this.checkFileExists(userLogoPath)
            .then(exists => {
                if (exists) {
                    this.setState({ logo: userLogoPath });
                } else {
                    this.setState({ logo: clientLogoPath });
                }
            })
            .catch(error => {
                console.error("Erro ao verificar user.logo:", error);
                this.setState({ logo: clientLogoPath });
            });
    }

    render() {
        let nav = null;

        if (ClientHelper.getClient().setup.type !== "full") {
            nav = <FreeNav path={this.props.path} />;
        } else {
            nav = <FullNav path={this.props.path} submenu={this.props.submenu} {...this.props} ref="fullNav" />;
        }

        return (
            <header className="App-header">
                <div className="logo col-sm-3 col-md-3 col-lg-2 navbar navbar-default navbar-fixed-top">
                    <img src={this.state.logo} className="App-logo" alt="logo" />
                </div>
                <div className="row">
                    <div className="tool-bar col-sm-9 col-md-9 col-lg-10 navbar navbar-default navbar-fixed-top">
                        {nav} {this.props.children}
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
