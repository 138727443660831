import UserHelper from "./UserHelper";
import StorageHelper from "./StorageHelper";
import Moment from "moment";
import "moment-timezone";
import "moment/min/locales";

class LocaleHelper {
    static getCalendarDateFormat() {
        let user = UserHelper.getUser();
        let currentLocale = StorageHelper.get("locale") || "pt";
        if (user.locale) {
            currentLocale = user.locale;
        }
        let format;
        switch (currentLocale) {
            case "en":
                format = "MM/DD/YYYY";
                break;
            default:
                format = "DD/MM/YYYY";
        }

        return format;
    }

    static getCalendarDayLabels(intl) {
        return [
            intl({ id: "calendar.dayLabels.sun" }),
            intl({ id: "calendar.dayLabels.mon" }),
            intl({ id: "calendar.dayLabels.tue" }),
            intl({ id: "calendar.dayLabels.wed" }),
            intl({ id: "calendar.dayLabels.thu" }),
            intl({ id: "calendar.dayLabels.fri" }),
            intl({ id: "calendar.dayLabels.sat" })
        ];
    }

    static getCalendarMonthLabels(intl) {
        return [
            intl({ id: "calendar.monthLabels.january" }),
            intl({ id: "calendar.monthLabels.february" }),
            intl({ id: "calendar.monthLabels.march" }),
            intl({ id: "calendar.monthLabels.april" }),
            intl({ id: "calendar.monthLabels.may" }),
            intl({ id: "calendar.monthLabels.june" }),
            intl({ id: "calendar.monthLabels.july" }),
            intl({ id: "calendar.monthLabels.august" }),
            intl({ id: "calendar.monthLabels.september" }),
            intl({ id: "calendar.monthLabels.october" }),
            intl({ id: "calendar.monthLabels.november" }),
            intl({ id: "calendar.monthLabels.december" })
        ];
    }

    static getLocale() {
        return StorageHelper.get("locale") || "pt";
    }

    static setLocale(lang) {
        StorageHelper.set("locale", lang);
    }

    static returnDateDiff(date, type = "days", intl) {
        Moment.locale(UserHelper.getUser().locale);

        // let dateUTC = Moment().utc().subtract(2 * 60, "minutes");
        // let diff = Moment(dateUTC).diff(Moment(date), type);

        let diff = Moment(Moment()).diff(date, type);
        let bsStyle,
            title,
            label = "";

        if (diff < 2) {
            bsStyle = "success";
            title = intl({ id: "grid.2hours" });
            label = Moment(date).fromNow();
            // label = Moment(date).from(dateUTC);
        } else if (diff < 24) {
            bsStyle = "info";
            title = intl({ id: "grid.24hours" });
            label = Moment(date).fromNow();
            // label = Moment(date).from(dateUTC);
        } else if (diff < 48) {
            bsStyle = "warning";
            title = intl({ id: "grid.48hours" });
            label = Moment(date).fromNow();
            // label = Moment(date).from(dateUTC);
        } else {
            bsStyle = "danger";
            title = intl({ id: "grid.more48hours" });
            label = Moment(date).fromNow();
            // label = Moment(date).from(dateUTC);
        }
        return {
            data: {
                bsStyle: bsStyle,
                title: title,
                label: label
            }
        };
    }

    static returnBatteryTitle(battery, intl) {
        let bsStyle,
            title = "";

        if (battery >= 70) {
            bsStyle = "success";
            title = intl({ id: "grid.upTo70" });
        } else if (battery >= 30) {
            bsStyle = "warning";
            title = intl({ id: "grid.69To30" });
        } else {
            bsStyle = "danger";
            title = intl({ id: "grid.lessThan30" });
        }
        return {
            data: {
                bsStyle: bsStyle,
                title: title
            }
        };
    }
}
export default LocaleHelper;
