import moment from "moment";

export default class NoticeEntity {
    message= "Carregando..."
    notice_view= {
        notices_id: null,
        no_notice: false,
        last_seen_notice: moment().format('YYYY-MM-DD')
    }

    constructor({ ...props }) {
        if (!props.notice_view) {
            delete props.notice_view;
        }

        Object.assign(this, props);
    }
}