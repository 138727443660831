import Api from "./../../Services/Api/Api";
import UserHelper from "./../../Utils/Helpers/UserHelper";

const post = (url, data, callback) => {
    return Api.post(url, data, function (res, err) {
        callback && callback(res, err);
    });
};

const UserService = {
    onChange() { },

    getDevices(props) {
        return Promise.resolve(Api.post("client/loadDevices", props.data, props.callback, null, props.instance));
    },

    getDevicesEmail(props) {
        return Promise.resolve(Api.post("client/loadDevicesEmail", props.data, props.callback, null, props.instance));
    },

    getUserDevices(instance, config, callback, selectedDevices = [], data) {
        Api.post("client/loadDevices", data, (res, err) => {
            if (!instance._isMounted) return;

            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    let opt = [];

                    if (config.onlyIsca) {
                        res.data.length > 0 &&
                            res.data.forEach((item) => {
                                if (item.deviceIdSatellite > 0) instance.setState({ showTechnology: true });
                                if (
                                    item.isIsca === "true" &&
                                    item.model.substring(0, 5) !== "ST310" &&
                                    item.model.substring(0, 5) !== "ST380" &&
                                    item.model.substring(0, 5) !== "ST390" &&
                                    item.model.substring(0, 5) !== "ST410" &&
                                    item.model.substring(0, 5) !== "ST419" &&
                                    item.model.substring(0, 5) !== "ST449" &&
                                    item.model.substring(0, 5) !== "ST940" &&
                                    item.model.substring(0, 5) !== "ST340" &&
                                    item.model.substring(0, 6) === "ST4410"
                                ) {
                                    opt.push({
                                        value: item.id,
                                        label: item.deviceId + (item.label ? " - " + item.label : ""),
                                        selected:
                                            selectedDevices.length > 0 &&
                                                selectedDevices.find((device) => device.value === item.id)
                                                ? true
                                                : false,
                                        checked: false,
                                        vehicle: item
                                    });
                                } else if (
                                    item.isIsca === "true" &&
                                    item.model.substring(0, 7) !== "ST400RT" &&
                                    item.model.substring(0, 7) !== "ST440RT" &&
                                    item.model.substring(0, 7) !== "ST420RT"
                                ) {
                                    opt.push({
                                        value: item.id,
                                        label:
                                            (item.deviceIdSatellite > 0 ? "* " : "") +
                                            item.licensePlate +
                                            (item.label ? " - " + item.label : "") +
                                            " (" +
                                            item.deviceId +
                                            ")",
                                        selected:
                                            selectedDevices.length > 0 &&
                                                selectedDevices.find((device) => device.value === item.id)
                                                ? true
                                                : false,
                                        checked: false,
                                        vehicle: item
                                    });
                                }
                            });
                    } else if (config.onlyTracker) {
                        res.data.length > 0 &&
                            res.data.forEach((item) => {
                                if (item.deviceIdSatellite > 0) instance.setState({ showTechnology: true });
                                if (
                                    item.isIsca === "false" ||
                                    (item.isIsca === "true" &&
                                        (item.model.substring(0, 5) === "ST380" ||
                                            item.model.substring(0, 5) === "ST310" ||
                                            item.model.substring(0, 5) === "ST390" ||
                                            item.model.substring(0, 5) === "ST410" ||
                                            item.model.substring(0, 5) === "ST419" ||
                                            item.model.substring(0, 5) === "ST449" ||
                                            item.model.substring(0, 5) === "ST940" ||
                                            item.model.substring(0, 5) === "ST340" ||
                                            item.model.substring(0, 6) === "ST4410"))
                                ) {
                                    opt.push({
                                        value: item.id,
                                        label:
                                            (item.deviceIdSatellite > 0 ? "* " : "") +
                                            item.licensePlate +
                                            (item.label ? " - " + item.label : "") +
                                            " (" +
                                            item.deviceId +
                                            ")",
                                        selected:
                                            selectedDevices.length > 0 &&
                                                selectedDevices.find((device) => device.value === item.id)
                                                ? true
                                                : false,
                                        checked: false,
                                        vehicle: item
                                    });
                                }
                            });
                    } else {
                        res.data.data.length > 0 &&
                            res.data.data.forEach((item) => {
                                if (item.deviceIdSatellite > 0) instance.setState({ showTechnology: true });
                                if (
                                    item.isIsca === "true" &&
                                    item.model.substring(0, 5) !== "ST380" &&
                                    item.model.substring(0, 5) !== "ST390" &&
                                    item.model.substring(0, 5) !== "ST410" &&
                                    item.model.substring(0, 5) !== "ST419" &&
                                    item.model.substring(0, 5) !== "ST449" &&
                                    item.model.substring(0, 6) === "ST4410"
                                ) {
                                    opt.push({
                                        value: item.id,
                                        label: item.deviceId + (item.label ? " - " + item.label : ""),
                                        selected:
                                            selectedDevices.length > 0 &&
                                                selectedDevices.find((device) => device.value === item.id)
                                                ? true
                                                : false,
                                        checked: false,
                                        vehicle: item
                                    });
                                } else {
                                    opt.push({
                                        value: item.id,
                                        label:
                                            (item.deviceIdSatellite > 0 ? "* " : "") +
                                            item.licensePlate +
                                            (item.label ? " - " + item.label : "") +
                                            " (" +
                                            item.deviceId +
                                            ")",
                                        selected:
                                            selectedDevices.length > 0 &&
                                                selectedDevices.find((device) => device.value === item.id)
                                                ? true
                                                : false,
                                        checked: false,
                                        vehicle: item,
                                        otherInformation: ""
                                    });
                                }
                            });
                    }

                    if (config) {
                        if (config.enableChooseAnOption) {
                            opt.unshift({
                                value: null,
                                label: instance.props.intl.formatMessage({
                                    id: "chooseOption"
                                }),
                                selected: true,
                                disabled: config.selectable ? false : true
                            });
                        }
                    }

                    instance.setState(
                        {
                            devices: opt,
                            lastPage: res.data.last_page,
                            totalLinesPage: res.data.total
                        },
                        () => {
                            if (callback) callback(res, err);
                        }
                    );
                }
            }
        });
    },

    getUserCollumns(data, callback) {
        Api.post("client/loadCollumns", data, function (res, err) {
            callback(res, err);
        });
    },

    getMotorists(instance, data) {
        Api.post("client/loadMotorists", data, (res, err) => {
            if (!instance._isMounted) return;
            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    instance.setState({
                        motorists: res.data.map((item) => {
                            return {
                                value: item.driver_id,
                                label: item.name,
                                selected: false,
                                checked: false,
                                motorists: item
                            };
                        })
                    });
                }
            }
        });
    },

    getEvents(instance, data) {
        Api.post("client/loadEvents", data, (res, err) => {
            if (!instance._isMounted) return;
            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    let opt = [];

                    res.data.map(function (item) {
                        return opt.push({
                            value: item.activation + "_" + item.activationID,
                            label: item.activationName,
                            selected: false,
                            checked: false,
                            events: item
                        });
                    });

                    instance.setState({ events: opt, showEvent: true });
                }
            }
        });
    },

    getAccountByUser(data, callback) {
        Api.post("user/loadAccounts", data, function (res, err) {
            callback(res, err);
        });
    },

    getAccountDevices(props) {
        // Api.post("user/loadAccountDevices", data, function(res, err) {
        //     callback(res, err);
        // });
        return Promise.resolve(Api.post("user/loadAccountDevices", props.data, props.callback, null, props.instance));
    },

    getAccountPermissions(data, callback) {
        Api.post("user/loadAccountPermissions", data, function (res, err) {
            callback(res, err);
        });
    },

    getAccountWorkSchedule(data, callback) {
        Api.post("user/loadAccountWorkSchedule", data, function (res, err) {
            callback(res, err);
        });
    },

    toogleMute(instance, data, callback) {
        Api.post("user/toogleMute", data, function (res, err) {
            if (!instance._isMounted) return;

            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    if (res.msg === "success" && callback) {
                        callback(res);
                        return;
                    }

                    let currentUser = UserHelper.getUser();
                    currentUser.sound = instance.state.hasSound ? "0" : "1";
                    UserHelper.setUser(currentUser);
                    instance.setState({ hasSound: !instance.state.hasSound });
                }
            }
        });
    },

    save(data, callback) {
        Api.post("user/save", data, function (res, err) {
            callback(res, err);
        });
    },

    updatePass(instance, data, callback) {
        Api.post("user/updatePass", data, function (res, err) {
            instance.setState({ sending: false, disableSave: false });
            if (!instance._isMounted) return;

            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    if (res.msg === "success" && callback) {
                        callback(res);
                        return;
                    }

                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "success",
                            msg: res.msg
                        }
                    });
                }
            }
        });
    },

    updateEmail(instance, data, callback) {
        Api.post("user/updateEmail", data, function (res, err) {
            if (!instance._isMounted) return;

            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    if (res.success === true && callback) {
                        callback(res);
                        return;
                    }

                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "success",
                            msg: res.msg
                        }
                    });
                }
            }
        });
    },

    // Usado para componentes função que não acessam this.setState()
    updateUserSettings(data) {
        return new Promise((resolve, reject) => {
            Api.post("user/updateSettings", data, (res, err) => {
                if (err) {
                    reject(err);
                } else {
                    UserHelper.setUser(res.user);
                    resolve(res);
                }
            });
        });
    },

    updateSettings(instance, data, callback) {
        Api.post("user/updateSettings", data, (res, err) => {
            if (instance && !instance._isMounted) return;
            instance.setState({ sending: false, disableSave: false });
            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    UserHelper.setUser(res.user);

                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "success",
                            msg: res.msg
                        },
                        settings: {
                            color: res.user.variables.color || "",
                            hourmeter: res.user.variables.hourmeter || "h",
                            internalBattery: res.user.variables.internalBattery || "v",
                            showFence: res.user.variables.showFence || "false",
                            showIconDirectionVehicle: res.user.variables.showIconDirectionVehicle || "false",
                            showTrail: res.user.variables.showTrail || "false",
                            hideEvents: res.user.variables.hideEvents || "false",
                            showLicensePlateOrLabel: res.user.variables.showLicensePlateOrLabel || "licensePlate",
                            showEventPopups: res.user.variables.showEventPopups || "false",
                            continuousSound: res.user.variables.continuousSound || "false",
                            showBarComunicationIsca: res.user.variables.showBarComunicationIsca || "false",
                            showBarComunicationTracker: res.user.variables.showBarComunicationTracker || "false",
                            showBarTemperature: res.user.variables.showBarTemperature || "false",
                            labelPosition: res.user.variables.labelPosition || "top"
                        },
                        clientStyle: UserHelper.getUserColor()
                    });

                    if (callback) {
                        callback(res);
                    }
                }
            }
        });
    },

    getAccessToken(data, callback) {
        post("user/access-token", data, callback);
    },

    createAccessToken(data, callback) {
        post("user/access-token/create", data, callback);
    },

    removeAccessToken(data, callback) {
        post("user/access-token/remove", data, callback);
    },

    remove(data, callback) {
        post("user/remove", data, callback);
    }
};

export default UserService;
