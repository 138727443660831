import React from "react";
import Api from "./../../Services/Api/Api";
import FontAwesome from "react-fontawesome";
import NoticeEntity from "../../model/NoticeEntity";
import moment from "moment";

const EventService = {
    getEvents(instance, data, callback, Intl) {
        Api.post("event/getEvents", data, function (res, err) {
            if (!instance._isMounted) return;

            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: false, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    if (res.msg === "success" && callback) {
                        callback(res);
                        return;
                    }

                    if (
                        instance.state.lastEvent !== 0 &&
                        res.data.lastEvent.greater > instance.state.lastEvent.greater
                    ) {
                        if (instance.state.hasSound) {
                            instance.state.soundAlert.play();
                        }

                        if (instance.state.eventPopup) {
                            let popup;

                            if (
                                res.data.lastEvent.lastEmergency > instance.state.lastEvent.lastEmergency &&
                                res.data.emergency.data.length
                            ) {
                                popup = res.data.emergency.data[0];
                            } else if (
                                res.data.lastEvent.lastEvent > instance.state.lastEvent.lastEvent &&
                                res.data.events.data.length
                            ) {
                                popup = res.data.events.data[0];
                            }

                            if (popup) {
                                instance.setState({
                                    genericAlert: {
                                        show: true,
                                        type: popup.activationType === "EMG" ? "danger" : "warning",
                                        msg: (
                                            <div>
                                                <p>
                                                    <FontAwesome name="flag" />
                                                    {"  "}
                                                    {popup.licensePlate}
                                                    {popup.label ? " - " + popup.label : ""} <br />
                                                    {popup.complement
                                                        ? popup.description + " - " + popup.complement
                                                        : popup.activationType === "ALT" && popup.activationId === "1"
                                                            ? popup.description + " - " + popup.speed + " km/h"
                                                            : popup.description}
                                                </p>
                                            </div>
                                        )
                                    }
                                });
                            }
                        }
                    }
                    if (instance.state.hasSound && instance.state.continuousSound) {
                        if (res.data.lastEvent.greater !== 0) {
                            instance.state.soundAlert.play();
                        }
                    }
                    let newState = {
                        emergencyCount: res.data.emergency.total,
                        eventsCount: res.data.events.total,
                        lastEvent: res.data.lastEvent,
                        notice: new NoticeEntity({ ...res.data.notice }),
                        visibleNoticeModel: false,
                    }

                    // logica para exibir ou nao o modal de notificacao
                    if (res.data.notice?.id > 0) {
                        newState.visibleNoticeModel = true;

                        if (res.data.notice.notice_view) {
                            let lastView = moment(res.data.notice.notice_view.last_seen_notice, 'YYYY-MM-DD');
                            let noNotice = res.data.notice.notice_view.no_notice;
                            console.log(lastView.diff(moment(), 'days'));
                            newState.visibleNoticeModel = noNotice === 0 && lastView.diff(moment(), 'days') < 0;
                        }
                    }

                    if (!instance.state.showModal) {
                        const pageState = {
                            emergencies: res.data.emergency.data,
                            events: res.data.events.data,
                            eventPage: 1,
                            emergencyPage: 1
                        }
                        newState = { ...newState, ...pageState }
                    }
                    instance.setState(newState);
                }
            }
        });
    },

    getDevicesByEventMail(props) {
        return Promise.resolve(
            Api.post("event/getDevicesByEventMail", props.data, props.callback, null, props.instance)
        );
    },

    getEventsByEventMail(data, callback) {
        Api.post("event/getEventsByEventMail", data, function (res, err) {
            callback(res, err);
        });
    },

    save(data, callback) {
        Api.post("event/saveUserEvents", data, function (res, err) {
            callback(res, err);
        });
    },

    removeUserEvents(instance, data, callback) {
        Api.post("event/removeUserEvents", data, function (res, err) {
            if (!instance._isMounted) return;

            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    if (res.msg === "success" && callback) {
                        callback(res);
                        return;
                    }

                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "success",
                            msg: res.msg
                        },
                        disableRemove: true,
                        disableSave: true,
                        email: "",
                        selectedDevices: []
                    });

                    instance._loadEvent();
                }
            }
            instance.setState({ sending: false });
        });
    },

    addComment(instance, index, data, callback) {
        Api.post("event/addComment", data, function (res, err) {
            if (!instance._isMounted) return;

            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    if (res.msg === "success" && callback) {
                        callback(res);
                        return;
                    }

                    let wrapper = instance.state.eventsList;
                    wrapper[index].comments.unshift(res.data);
                    wrapper[index].comment = "";
                    wrapper[index].msg = res.msg;
                    instance.setState({ eventsList: wrapper });
                }
            }
        });
    },

    finishEvent(instance, index, data, callback) {
        Api.post("event/finishEvent", data, function (res, err) {
            if (!instance._isMounted) return;

            if (err === "failed") {
                instance.setState({
                    genericAlert: { show: true, type: "danger" }
                });
            } else {
                if (res.error !== 0) {
                    instance.setState({
                        genericAlert: {
                            show: true,
                            type: "danger",
                            msg: res.msg
                        }
                    });
                } else {
                    if (res.success && callback) {
                        callback(res);
                    }
                    if (index !== undefined) {
                        let wrapper = instance.state.eventsList;
                        wrapper.splice(index, 1);
                        instance.setState({ eventsList: wrapper });
                    } else {
                        instance.setState({ eventsList: [], showModal: false });
                    }
                }
            }
            instance.setState({ finishAllStatus: false });
        });
    },

    dontShowNoticeAgain(instance, data) {
        Api.post("notice/saveNotShowNoticeView", data, function (res, err) {
            let notice_view = instance.state.notice.notice_view;
            notice_view.no_notice = data.no_notice;

            instance.setState({
                notice: {
                    ...instance.notice,
                    notice_view: notice_view
                }
            })
        });
    },

    updateLastSeenNotice(instance, data) {
        Api.post("notice/saveLastNoticeView", data, function (res, err) {
            let notice_view = instance.state.notice.notice_view;
            notice_view.no_notice = data.no_notice;

            instance.setState({
                notice: {
                    ...instance.notice,
                    notice_view: notice_view
                }
            })
        });
    }
};

export default EventService;
