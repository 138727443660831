import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

class NotFound extends Component {
    render() {
        return (
            <div className="row paddingTop">
                <div className="col-md-2" />
                <div className="col-md-8">
                    <div className="panel panel-warning">
                        <div className="panel-heading">
                            <h3 className="text-center">
                                <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true" /> Oops:{" "}
                                <small>
                                    <b>Erro 404</b>
                                </small>
                            </h3>
                        </div>
                        <div className="panel-body text-center">
                            <h3>
                                <FormattedMessage id="login.notFound" />
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(NotFound);
