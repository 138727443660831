import StorageHelper from "../Helpers/StorageHelper";

const ClientHelper = {
    getClient() {
        if (StorageHelper.get("client")) return JSON.parse(StorageHelper.get("client"));

        return {};
    },

    setClient(client) {
        StorageHelper.set("client", client);
    }
};

export default ClientHelper;
