export const Config = {
    env: process.env.REACT_APP_BUILD_ENV || "PROD",
    basename: process.env.REACT_APP_SYSTEM || "webcliente",
    api: {
        LOCAL: {
            urlRC: "//ap3.stc.srv.br/rc-backend/movit/",
            url: "//localhost:8484/sys/",
            contractUrl: "http://ap3.stc.srv.br/arquivos/contratos/"
        },
        DEV: {
            urlRC: "//ap3.stc.srv.br/rc-backend/movit/",
            url: `//${process.env.REACT_APP_WEBSERVICE_URL}/integration/dev/sys/`,
            contractUrl: "http://ap3.stc.srv.br/arquivos/contratos/"
        },
        STAGE: {
            urlRC: "//ap3.stc.srv.br/rc-backend/movit/",
            url: "//ap3.stc.srv.br/integration/stage/sys/",
            contractUrl: "http://ap3.stc.srv.br/arquivos/contratos/"
        },
        HOMOLOG: {
            urlRC: "//ap3.stc.srv.br/rc-backend/movit/",
            url: "//ap3.stc.srv.br/integration/homolog/sys/",
            contractUrl: "http://ap3.stc.srv.br/arquivos/contratos/"
        },
        PROD: {
            urlRC: "//ap3.stc.srv.br/rc-backend/movit/",
            url: "//ap3.stc.srv.br/integration/prod/sys/",
            contractUrl: "http://ap3.stc.srv.br/arquivos/contratos/"
        },
        PRODLGPD: {
            urlRC: "//ap3.stc.srv.br/rc-backend/movit/",
            url: `//${process.env.REACT_APP_WEBSERVICE_URL}/integration/lgpd/sys/`,
            contractUrl: "http://ap3.stc.srv.br/arquivos/contratos/"
        }
    },

    logo: {
        path: "//stc-static-files.s3.amazonaws.com/mobile/logo/",
        userPath: "//ap3.stc.srv.br/images/"
    },

    icon: {
        type: "//stc-static-files.s3.amazonaws.com/mobile/icons/"
    },

    system: {
        dashboard: {
            scheduleTime: 15 * 60 * 1000 // 15 minutes
        }
    },

    OSM: {
        config: {
            uri: "//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            params: {
                zoom: 18,
                attribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
                id: "",
                accessToken: ""
            }
        }
    },
    HERE: {
        config: {
            uri: "//1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=D23u5NRRXw86sJpESjJz&app_code=uv934KmGfN0ky4H76NcpbA",
            params: {
                zoom: 18,
                attribution: "&copy; <a href='https://www.here.com/'>Here</a> contributors",
                id: "",
                accessToken: ""
            }
        }
    },
    MAPBOX: {
        config: {
            uri: "//api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3RiZGVzZW52b2x2aW1lbnRvIiwiYSI6ImNrMTBzeGJsdTAyb3kzaG9kZGgzanhybXEifQ.S22WRUqOFdLgQ9tBuVfGrQ",
            params: {
                zoom: 18,
                attribution:
                    '&copy; <a href="https://www.mapbox.com/about/maps/">© Mapbox | </a> <a href="http://www.openstreetmap.org/copyright">© OpenStreetMap | </a><a href="https://www.mapbox.com/map-feedback/" target="_blank"><strong>Improve this map</strong></a>',
                id: "",
                accessToken: ""
            }
        }
    },
    GMap: {
        initial: { lat: -23.606016, lng: -46.65032 },
        circleOptions: {
            fillColor: "#F60000",
            strokeColor: "#F60000",
            fillOpacity: 0.1,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            clickable: true,
            editable: false,
            zIndex: 1
        },
        polygonOptions: {
            strokeColor: "#ff0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#ff0000",
            fillOpacity: 0.1,
            clickable: false
        }
    }
};

export default Config;
