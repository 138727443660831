import React, { Component } from "react";

import NotFound from "./Pages/NotFound";
import LoaderPage from "./Elements/Loader/LoaderPage";
import StorageHelper from "./Utils/Helpers/StorageHelper";
import ClientHelper from "./Utils/Helpers/ClientHelper";
import UserHelper from "./Utils/Helpers/UserHelper";

class ComponentFactory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            RouteComponent: LoaderPage,
            sharedPermissions: [{ lwp_nome: "command", alias: "commandKeyboard" }]
        };

        this.checkUserAuth = () => {
            let client = ClientHelper.getClient();
            let currentPath = this.props.match.path.split("/");
            let checkPermission = false;

            if (!UserHelper.checkPermission(currentPath[2])) {
                this.state.sharedPermissions.forEach((sharedPermission, i) => {
                    if (currentPath[2] === sharedPermission.lwp_nome) {
                        if (UserHelper.checkPermission(sharedPermission.alias)) {
                            checkPermission = true;
                        }
                    }
                });
            } else {
                checkPermission = UserHelper.checkPermission(currentPath[2]);
            }

            if (
                !StorageHelper.get("user") ||
                !client ||
                this.props.match.params.clientName.trim() !== client.path.trim() ||
                !checkPermission
            ) {
                UserHelper.logout();
                return false;
            } else {
                return true;
            }
        };
    }

    componentDidMount() {
        const { dynamicImport, access, history } = this.props;
        const component = dynamicImport;

        if (component !== undefined) {
            (async () => {
                await import("./" + dynamicImport)
                    .then((module) => {
                        if (access === "private" && !this.checkUserAuth()) {
                            history.push("/" + this.props.match.params.clientName + "/login");
                            return;
                        }
                        this.setState({ RouteComponent: module.default });
                    }) //redirect
                    .catch((err) => {
                        this.setState({ RouteComponent: NotFound });
                        console.error(err);
                    });
            })();
        }
    }

    render() {
        const { RouteComponent } = this.state;

        return (
            <div>
                <RouteComponent {...this.props} />
            </div>
        );
    }
}

export default ComponentFactory;
